import React, { useState } from 'react';
import RegisterButton from './RegisterButton';
import { logData } from "../api.js";
import RegisterForm from './RegisterForm.js';

const CourseSummary = ({ showRegisterDialogParent }) => {
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
        const referrer = document.referrer || "No referrer";
        const extraData = { referrer: referrer, state: !showDetails };
        logData("openSummary", extraData)
            .catch(() => {

            });
    };

    return (
        <section className="bg-2">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12" style={{ padding: "15px", paddingTop: "0px" }}>
                        <div className='row label_text' style={{ color: "#333", marginTop: "0px" }}>
                            {/* <div className='mb-5'>
                                <RegisterForm id="summary" isFormOpenInitial={true}></RegisterForm>
                            </div> */}
                            <div className="col">
                                <h2>Ce înveți la curs?</h2>
                                <div className="label_text_summary_large" style={{ marginBottom: "5px" }}>
                                    {/* În acest curs vei descoperi pas cu pas ce este AI și <b>cum funcționează un Large Language Model</b> (ChatGPT este un LLM), într-un mod clar și ușor de înțeles. Vei învăța <b>cum să creezi prompturi eficiente</b> și să folosești tehnici avansate de prompting pentru a obține cele mai bune rezultate. Vom vedea <b>cum AI-ul ne poate ajuta să generăm atât texte, cât și imagini</b>. Vom scrie împreună o poveste pentru copii, dar și <b>răspunsuri la cererile de suport venite de la clienții tăi</b>. Vom explora cum aceste abilități de a folosi AI-ul pot fi <b>aplicate practic în interacțiunile din business</b>. Totul într-un mod intuitiv, cu exemple și experimente practice. */}
                                    {/* <ul>
                                        <li>Descoperi pas cu pas ce este AI și cum funcționează un Large Language Model (LLM), precum ChatGPT.</li>
                                        <li>Înveți cum să creezi prompturi eficiente și să folosești tehnici avansate de prompting (Few Shot Prompting, Chain of Thought, Self Consistency sau ReAct) pentru rezultate optime.</li>
                                        <li>Explorezi cum AI-ul poate genera orice tip de conținut: text, imagini, podcast-uri, muzică.</li>
                                        <li>Scriem împreună o poveste pentru copii și o ilustrăm folosind AI-ul.</li>
                                        <li> Învățăm cum să cerem AI-ului să răspundă cererilor de suport primite de la clienții tăi.</li>
                                        <li>Aflăm cum aceste abilități pot fi aplicate în interacțiunile de business.</li>
                                        <li>Totul este prezentat într-un mod intuitiv, cu exemple și experimente practice.</li>
                                    </ul> */}
                                    {/* Descoperi cum să folosești inteligența artificială pentru a crea diverse tipuri de conținut și a automatiza interacțiuni și procese, aplicând tehnici avansate de prompting într-un mod practic și intuitiv. */}
                                    Vei învăța cum să utilizezi inteligența artificială pentru a-ți simplifica munca și pentru a automatiza diverse sarcini creative și operaționale. Cursul îți arată cum să folosești AI-ul într-un mod practic și aplicabil. Este o combinație de explicații simple și exemple concrete care te ajută să înțelegi și să aplici ceea ce înveți, fără teorie complicată.
                                </div>

                                <button className="btn btn-secondary" style={{ fontSize: "0.85em", marginBottom: "15px" }} onClick={toggleDetails}>
                                    {showDetails ? 'Ascunde cuprinsul detaliat' : 'Vezi cuprinsul detaliat'}
                                </button>

                                {showDetails && (
                                    <div className="label_text_summary_large" style={{ backgroundColor: "#f7f7f7", padding: "20px", marginTop: "20px", borderRadius: "10px", lineHeight: "1.6" }}>
                                        <div className="label_text_summary">
                                            <strong>Lecția 1: Introducere în AI:</strong> În prima lecție, vei învăța ce este AI-ul și cum funcționează ChatGPT. Vom explora cum folosești deja AI în viața de zi cu zi și cum îl poți integra mai bine în activitățile tale. De asemenea, vei înțelege cum funcționează un model de limbaj mare (LLM) și cum poate fi folosit pentru sarcini zilnice.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 2: Ce NU este ChatGPT:</strong> În această lecție vom clarifica unele concepții greșite despre ChatGPT. Discutăm despre ce nu este ChatGPT. Vom înțelege procesele interne care transformă o întrebare într-un răspuns inteligent și coerent.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 3: Cum învață inteligența artificială:</strong> Vom descoperi cum funcționează procesul de învățare al unui sistem cu inteligență artificială (cum este și ChatGPT). Vei înțelege de ce nu este vorba de o simplă căutare într-o bază de date, ci de un proces complex de generare a răspunsurilor pe baza textelor antrenate.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 4: Puterea prompturilor:</strong> Vom învăța cum să formulăm prompturi pentru a obține cele mai bune rezultate de la AI. Prompturile bine formulate asigură că AI-ul va livra răspunsuri cât mai relevante și adaptate nevoilor noastre.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 5: Tehnici avansate de prompting:</strong> În această lecție vom explora tehnici avansate precum "Chain of Thought" și "Act-As" care permit AI-ului să abordeze sarcini complexe și să genereze răspunsuri mai detaliate.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 6: Crearea de prompturi eficiente:</strong> Vom explora ce înseamnă un prompt clar, concis și relevant, cu exemple practice și instrucțiuni detaliate. Vom învăța să rafinăm interacțiunile cu AI-ul, similar cu modul în care purtăm o discuție cu un partener uman.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 7: Few-shot prompting și self-consistency:</strong> Aceste tehnici îți permit să oferi AI-ului exemple concrete pentru a obține răspunsuri mai precise și să folosești verificarea iterativă pentru a îmbunătăți calitatea rezultatelor.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 8: Chain of Thought și Prompt Chaining:</strong> Această lecție îți prezintă tehnici esențiale pentru descompunerea problemelor complexe în pași mai simpli și adăugarea de informații noi pentru a obține răspunsuri mai precise.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 9: Aplicații practice: sumarizare și traducere:</strong> În această lecție vei aplica practicile învățate pentru sumarizarea textelor complexe și traducerea acestora, adaptând AI-ul la contextul cultural și emoțional specific.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 10: Tree of Thoughts, RAG și ReAct:</strong> Lecția introduce trei tehnici avansate care sunt esențiale pentru sarcini complexe și interacțiuni cu sisteme externe.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 11: Generarea de imagini cu AI:</strong> Ne concentrăm pe cum poți folosi AI-ul pentru a transforma textul în imagini captivante folosind modele precum DALL-E, Midjourney și Stable Diffusion.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 12: Utilizarea practică a platformelor AI:</strong> Această lecție ne va ghida prin utilizarea practică a platformelor AI pentru a genera imagini și a le personaliza în funcție de nevoile tale.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 13: Explorarea altor modele AI:</strong> Vom explora alte platforme și modele AI disponibile pentru generarea de imagini și vom învăța cum să experimentezi cu diverse abordări.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 14: Crearea unei povești pentru copii:</strong> Vom aplica toate cunoștințele acumulate pentru a crea o poveste, trecând prin pași de brainstorming, dezvoltarea personajelor și crearea intrigii.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 15: Ilustrarea poveștii:</strong> Vei învăța cum să ilustrezi povestea creată anterior, testând diverse stiluri de ilustrație și asigurând consistența vizuală între scene.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 16: Utilizarea AI în suportul pentru clienți:</strong> Vom explora cum să folosești AI-ul pentru a genera răspunsuri rapide și eficiente pentru cererile de suport venite de la clienți.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 17: Descoperă scrierea creativă cu AI:</strong> Învățăm să transformăm ChatGPT într-un partener de scris care îți reflectă stilul personal. Folosind articole mai vechi, vei descoperi cum să configurezi AI-ul să scrie autentic, păstrându-ți vocea creativă în fiecare text.
                                        </div>

                                        <div className="label_text_summary">
                                            <strong>Lecția 18: Înțelege halucinațiile în AI:</strong> Explorăm ce sunt halucinațiile și cum apar în modelele lingvistice. Descoperi metode de identificare și prevenire, precum și tehnici pentru a ghida AI-ul spre răspunsuri corecte, reducând riscurile de informații false.
                                        </div>

                                        <div className="label_text_summary">
                                            <strong>Lecția 19: De ce apar halucinațiile?</strong> Analizăm cauzele tehnice ale halucinațiilor și limitările fundamentale ale modelelor lingvistice. Vom învăța cum să folosim gândirea critică și instrumente practice pentru a verifica informațiile și a minimiza erorile.
                                        </div>

                                        <div className="label_text_summary">
                                            <strong>Lecția 20: Recapitulăm și consolidăm bunele practici:</strong> Revedem strategii esențiale pentru interacțiuni eficiente cu ChatGPT. De la alegerea modelelor premium până la verificarea informațiilor și evitarea halucinațiilor, această lecție pune accent pe utilizarea practică a AI-ului.
                                        </div>

                                        <div className="label_text_summary">
                                            <strong>Lecția 21: Obține mai mult cu funcția de căutare și context:</strong> Afli cum să folosești căutarea pe internet din ChatGPT pentru informații actuale și relevante. În plus, învățăm să creăm un context solid pentru a primi răspunsuri mai bine adaptate nevoilor tale.
                                        </div>

                                        <div className="label_text_summary">
                                            <strong>Lecția 22: Precizia în cerințe și prompturi eficiente:</strong> Descoperim cum claritatea cerințelor influențează răspunsurile ChatGPT. Vei învăța să creezi prompturi personalizate și să maximizezi utilitatea modelului prin exemple concrete de traduceri, brainstorming și sumarizare.
                                        </div>

                                        <div className="label_text_summary">
                                            <strong>Lecția 23: Prevenirea halucinațiilor și avantajele limbii engleze:</strong> Aflăm de ce răspunsurile în engleză sunt mai precise și cum putem preveni halucinațiile folosind strategii ca cererea de surse, justificări și permisiunea de a spune "nu știu".
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 24: Cum să folosești AI-ul pentru a lua decizii rapide și eficiente:</strong> În această lecție vei învăța cum să utilizezi ChatGPT și căutarea pe internet pentru a lua decizii informate. Vom explora metode prin care AI-ul te poate ajuta să compari produse, să filtrezi informațiile relevante și să organizezi mai eficient opțiunile disponibile, economisind timp prețios.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 25: Design interior cu AI:</strong> În această lecție explorăm cum poți folosi AI pentru a vizualiza și transforma un spațiu. Vom învăța cum să generăm multiple variante de design, să experimentăm cu culori, mobilier și stiluri și să utilizăm tehnici precum inpainting pentru a modifica detalii specifice într-o cameră.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 26: Cum să folosești ChatGPT pentru analiză avansată de date:</strong> În această lecție vei învăța cum să utilizezi funcția Advanced Data Analysis pentru a lucra cu fișiere Excel și CSV. Vom explora cum AI-ul generează cod Python pentru a efectua calcule precise, analiza datelor și crea vizualizări relevante, eliminând riscul de erori și halucinații.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 27: Cum să folosești ChatGPT pentru a crea vizualul unei pagini de start:</strong> În această lecție vei învăța cum să utilizezi AI pentru a genera rapid variante de pagini web. Vom explora cum ChatGPT te poate ajuta în brainstorming, definirea structurii vizuale și generarea de imagini pentru designul paginii folosind DALL-E. De asemenea, vom vedea cum să aplici tehnica inpainting pentru ajustări precise ale elementelor grafice.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 28: Despre agenți AI:</strong> În această lecție vei descoperi cum agenții AI colaborează pentru a îndeplini sarcini complexe. Vom explora modul în care agenții specializați, precum cei pentru comunicare, logistică și suport financiar, lucrează împreună pentru a automatiza și eficientiza procese precum gestionarea comenzilor întârziate sau oferirea de suport clienților.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 29: Despre agenți AI - pe larg:</strong> În această lecție vom explora ce sunt agenții AI și cum diferă de chatboții obișnuiți. Vom vedea cum aceștia pot lua decizii autonome, accesa resurse externe și executa sarcini complexe, de la planificarea unei vacanțe până la automatizarea proceselor de business.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 30: Despre agenți AI - pe larg (2):</strong> În această lecție vom analiza în detaliu cum funcționează agenții AI. Vom explora componentele esențiale ale unui agent AI - modelul de limbaj, accesul la unelte externe și capacitatea de luare a deciziilor - și vom vedea prin exemple practice cum acestea colaborează pentru a îndeplini sarcini complexe.
                                        </div>
                                        <div className="label_text_summary">
                                            <strong>Lecția 31: Despre agenți AI - pe larg (3):</strong> În această lecție vom explora exemple concrete de agenți AI deja folosiți în companii și industrii diverse. Vom vedea cum aceștia automatizează sarcini precum generarea de rapoarte, suportul tehnic și cercetarea online, demonstrând impactul lor în eficientizarea proceselor de afaceri și creșterea productivității.
                                        </div>








                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CourseSummary;
